"use client";

import { startTransition } from "react";

import { useLocale } from "next-intl";
import { useParams } from "next/navigation";

import css from "@/src/components/header/menu/language/language.module.css";
import { getMenuLinkClassName } from "@/src/components/header/menu/menu-link";
import { Locale, getAvailableLocalesToFullName } from "@/src/intl/locale";
import { usePathname, useRouter } from "@/src/intl/navigation";
import { WithChildren } from "@/src/types/generic-react-props";

interface LanguageLinkProps extends WithChildren {
  locale: Locale;
}

const LanguageLink = ({ locale, children }: LanguageLinkProps) => {
  const router = useRouter();
  const pathname = usePathname();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const params = useParams() as never;

  const onClick = () => {
    startTransition(() => {
      router.replace({ pathname, params }, { locale });
    });
  };

  return (
    <button
      onClick={onClick}
      className={getMenuLinkClassName({ removeHoverMarginY: true })}
    >
      {children}
    </button>
  );
};

const LanguageList = () => {
  const currentLocale = useLocale();

  return (
    <ul className={css.languages}>
      {Object.entries(getAvailableLocalesToFullName(true, currentLocale)).map(
        ([locale, name]) => (
          <li key={locale}>
            <LanguageLink locale={locale as Locale}>{name}</LanguageLink>
          </li>
        ),
      )}
    </ul>
  );
};

export default LanguageList;
