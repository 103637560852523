import clsx from "clsx";

import { ReactNode } from "react";

import {
  ExternalLink,
  Href,
  InternalLink,
  isInternalHref,
} from "@/src/intl/link";
import { Locale } from "@/src/intl/locale";

import css from "./menu.module.css";

interface MenuLinkProps {
  removeHoverMarginY?: boolean;
  className?: string;
  locale?: Locale;
  children: ReactNode;
  href: Href;
}

export const getMenuLinkClassName = ({
  removeHoverMarginY,
  className,
}: Pick<MenuLinkProps, "removeHoverMarginY" | "className">): string =>
  clsx(
    css.menuLink,
    removeHoverMarginY && css.removeHoverMarginY,
    "sds-typography-link",
    className,
  );

const MenuLink = ({ href, children, locale, ...props }: MenuLinkProps) =>
  isInternalHref(href) ? (
    <InternalLink
      {...props}
      href={href}
      locale={locale}
      className={getMenuLinkClassName(props)}
    >
      {children}
    </InternalLink>
  ) : (
    <ExternalLink
      {...props}
      href={href}
      className={getMenuLinkClassName(props)}
    >
      {children}
    </ExternalLink>
  );

export default MenuLink;
