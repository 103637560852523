import clsx from "clsx";

import { Logo as SDSLogo } from "@sikt/sds-logo";

import { ExternalLink } from "@/src/intl/link";
import { Locale } from "@/src/intl/locale";

import css from "./footer.module.css";

interface LogoProps {
  locale: Locale;
}

const Logo = ({ locale }: LogoProps) => (
  <div className={css.logo}>
    <ExternalLink
      href="https://sikt.no/"
      className={clsx("sds-typography-link", css.link)}
      ignoreSdsStyling
    >
      <SDSLogo variant="secondary" lang={locale} />
    </ExternalLink>
  </div>
);

export default Logo;
