import { useEffect, useState } from "react";

export const useBodyFontSize = () => {
  const [fontSize, setFontSize] = useState(16);

  useEffect(() => {
    const body = document.querySelector("body");

    if (body) {
      setFontSize(parseFloat(window.getComputedStyle(body).fontSize));
    }
  }, []);

  return fontSize;
};
