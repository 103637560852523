import { useEffect } from "react";

export const useResizeObserver = <T extends HTMLElement>(
  element: T | null,
  callback: ResizeObserverCallback,
) => {
  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver(callback);

      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [element, callback]);
};
