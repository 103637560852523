import clsx from "clsx";

import { AnchorHTMLAttributes, ComponentProps, DetailedHTMLProps } from "react";

// eslint-disable-next-line next-intl/only-next-intl-link
import { Link as SDSLink, LinkProps as SDSLinkProps } from "@sikt/sds-core";

import { Pathname } from "./locale";
import { Link } from "./navigation";

export type LinkProps = ComponentProps<typeof Link<Pathname>>;
export type HrefInternal = LinkProps["href"];
const externalExtensions = ["http", "https", "mailto", "tel", "sms"] as const;
export type HrefExternal = `${(typeof externalExtensions)[number]}:${string}`;
export type Href = HrefInternal | HrefExternal;

/*
 * Custom types for handling pathnames and hrefs
 * Needed because pathnames are not exclusively strings anymore
 * Also, we need to handle external links differently
 */

const startsWithExternalExtension = (href: Href): boolean =>
  typeof href === "string" &&
  externalExtensions.some((extension) => href.startsWith(extension));

// Makes it easier to check if a link is internal or external (for type checking)
export const isExternalHref = (href: Href): href is HrefExternal =>
  startsWithExternalExtension(href);

// Makes it easier to check if a link is internal or external (for type checking)
export const isInternalHref = (href: Href): href is HrefInternal =>
  !(typeof href === "string") || !startsWithExternalExtension(href);

/*
 * These components are used to handle internal and external links,
 * and makes it easier to make custom link components, e.g. on the form:
 *
 * const Link = ({ href, children }) => isInternalHref(href) ? (
 *   <InternalLink href={href}>{children}</InternalLink>
 * ) : (
 *   <ExternalLink href={href}>{children}</ExternalLink>
 * );
 * */

type CommonLinkProps = Omit<SDSLinkProps, "href" | "isExternal"> & {
  /** For use, when one needs to wrap links in other components like ButtonLink, and one needs the styling to remain blank. */
  ignoreSdsStyling?: boolean;
};

type ExternalLinkProps = CommonLinkProps &
  DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & {
    href: HrefExternal;
  };

export const ExternalLink = ({
  children,
  ignoreSdsStyling,
  ...props
}: ExternalLinkProps) =>
  ignoreSdsStyling ? (
    <a {...props}>{children}</a>
  ) : (
    <SDSLink {...props} isExternal>
      {children}
    </SDSLink>
  );

type InternalMenuLinkProps = CommonLinkProps &
  LinkProps & {
    href: HrefInternal;
  };

export const InternalLink = ({
  noIcon,
  className,
  ignoreSdsStyling,
  ...props
}: InternalMenuLinkProps) => (
  <Link<Pathname>
    {...props}
    className={
      ignoreSdsStyling
        ? className
        : clsx(
            "sds-typography-link",
            noIcon && "sds-typography-link--no-icon",
            className,
          )
    }
  />
);

// Helps with extracting just the pathname part of either a pathname string or an object with a pathname
export const getPathname = (href: Href): string =>
  typeof href === "object" ? href.pathname : href;
