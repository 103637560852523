"use client";

import { useTranslations } from "next-intl";

import { Header } from "@sikt/sds-header";

import ButtonLink from "@/src/components/button-link/button-link";

const StrippedHeader = () => {
  const t = useTranslations("header");

  return (
    <Header logoText={t("title")}>
      <ButtonLink href="/login">{t("login")}</ButtonLink>
    </Header>
  );
};

export default StrippedHeader;
