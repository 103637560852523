import { Context, useContext } from "react";

/* Require the given context to be within a corresponding provider, and return the context value set by the provider */
export const useNonNullableContext = <T,>(
  context: Context<T | undefined>,
  useContextName?: string,
): NonNullable<T> => {
  const value = useContext(context);

  /* NOTE: use double equals instead of triple, to include undefined */
  if (value == null) {
    throw new TypeError(
      `'${useContextName ?? "useContext"}' has no corresponding provider`,
    );
  }

  return value;
};
