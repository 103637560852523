import { useCallback, useEffect, useRef } from "react";

/*
Currently only dragging along the x-axis is supported, but it should be easy
  to expand to also support the y-axis, if needed.
 */
export const useClickAndDrag = (
  element: HTMLElement | null,
  onDrag: (totalMovedX: number) => void,
  onDragStart?: (mouseX: number) => void,
  onDragEnd?: () => void,
  threshold = 5,
) => {
  const initialXRef = useRef(0);
  const isDraggingRef = useRef(false);
  const isMousePressedRef = useRef(false);
  const preventClickRef = useRef(false);

  const handleMouseDown = useCallback((event: MouseEvent) => {
    if (event.button === 0) {
      event.preventDefault();
      initialXRef.current = event.clientX;
      isMousePressedRef.current = true;
      /*
        NOTE: the 'click' event does not always fire in some browsers, such as
          with fagomrade-filter-horizontal-scroll when it uses an overlay,
          so preventClickRef is reset here as well.
         */
      preventClickRef.current = false;
    }
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const mouseX = event.clientX;

      if (isDraggingRef.current) {
        event.preventDefault();

        /*
        NOTE: another method is to calculate the distance moved since the last
          mousemove event, instead of the total distance moved since start,
          but the latter method, used below, is more reliable across browsers
          when browser zoom or OS-scaling is active.
         */
        const totalMovedX = initialXRef.current - mouseX;

        onDrag(totalMovedX);
      } else if (
        isMousePressedRef.current &&
        Math.abs(mouseX - initialXRef.current) > threshold
      ) {
        isDraggingRef.current = true;
        preventClickRef.current = true;
        initialXRef.current = mouseX;
        if (onDragStart) {
          onDragStart(mouseX);
        }
      }
    },
    [threshold, onDrag, onDragStart],
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent) => {
      isMousePressedRef.current = false;

      if (isDraggingRef.current) {
        event.preventDefault();
        isDraggingRef.current = false;
        if (onDragEnd) {
          onDragEnd();
        }
      }
    },
    [onDragEnd],
  );

  const handleClick = useCallback((event: MouseEvent) => {
    if (preventClickRef.current) {
      event.preventDefault();
      preventClickRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("click", handleClick);

      return () => {
        element.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.removeEventListener("click", handleClick);
      };
    }
  }, [element, handleMouseDown, handleMouseMove, handleMouseUp, handleClick]);
};

export const useClickAndDragScroll = (
  element: HTMLElement | null,
  onDragScrollStart?: () => void,
  onDragScrollEnd?: () => void,
  threshold?: number,
) => {
  const speedTimeRef = useRef(0);
  const speedXRef = useRef(0);
  const lastTotalMovedX = useRef(0);
  const scrollLeftPosRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const preventClickRef = useRef(false);

  const updateSpeed = (totalMovedX: number) => {
    /*
    NOTE: keep track of the speed of the scroll, for the last approximately 300ms,
      used to create a smooth slowing down effect when the user releases the drag.
     */
    const now = Date.now();
    const time = now - speedTimeRef.current;
    const currentSpeed = totalMovedX - lastTotalMovedX.current;
    const addPreviousSpeed =
      time < 300 &&
      (currentSpeed === 0 ||
        (currentSpeed > 0 && speedXRef.current > 0) ||
        (currentSpeed < 0 && speedXRef.current < 0));

    const speedX = currentSpeed + (addPreviousSpeed ? speedXRef.current : 0);

    speedTimeRef.current = now;
    speedXRef.current = speedX;
    lastTotalMovedX.current = totalMovedX;
  };

  const onDragStart = (mouseX: number) => {
    scrollLeftPosRef.current = element?.scrollLeft ?? 0;
    lastTotalMovedX.current = mouseX;
    if (onDragScrollStart) {
      onDragScrollStart();
    }
  };

  const onDrag = (totalMovedX: number) => {
    element?.scrollTo({
      left: scrollLeftPosRef.current + totalMovedX,
      behavior: "instant",
    });
    updateSpeed(totalMovedX);
  };

  const onDragEnd = () => {
    if (Date.now() - speedTimeRef.current < 50) {
      const speedXFactor = speedXRef.current / 30;
      const totalCount = 50;

      const onTimeout = (count: number) => {
        const scroll = speedXFactor * (count / totalCount) ** 3;

        if (count > 0 && Math.abs(scroll) >= 1) {
          element?.scrollBy({
            left: scroll,
            behavior: "instant",
          });
          timeoutRef.current = setTimeout(onTimeout, 5, count - 1);
        } else {
          timeoutRef.current = undefined;
        }
      };

      onTimeout(totalCount);
    }
    if (onDragScrollEnd) {
      onDragScrollEnd();
    }
  };

  const handleMouseDown = useCallback((event: MouseEvent) => {
    if (timeoutRef.current) {
      event.preventDefault();
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
      preventClickRef.current = true;
    } else if (preventClickRef.current) {
      preventClickRef.current = false;
    }
  }, []);

  const handleClick = useCallback((event: MouseEvent) => {
    if (preventClickRef.current) {
      event.preventDefault();
      preventClickRef.current = false;
    }
  }, []);

  useClickAndDrag(element, onDrag, onDragStart, onDragEnd, threshold);

  useEffect(() => {
    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      element.addEventListener("click", handleClick);

      return () => {
        element.removeEventListener("mousedown", handleMouseDown);
        element.removeEventListener("click", handleClick);
      };
    }
  }, [element, handleMouseDown, handleClick]);
};
