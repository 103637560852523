import { useState } from "react";

interface ReturnValues {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  setValue: (value: boolean) => void;
}

export const useBoolean = (defaultValue = false): ReturnValues => {
  const [value, setValue] = useState(defaultValue);

  const setTrue = () => {
    setValue(true);
  };

  const setFalse = () => {
    setValue(false);
  };

  return { value, setTrue, setFalse, setValue };
};
