"use client";

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";

import { useNonNullableContext } from "@/src/components/hooks";

type SetMenuIsOpen = Dispatch<SetStateAction<boolean>>;

interface MenuContextProps {
  menuIsOpen: boolean;
  setMenuIsOpen: SetMenuIsOpen;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const useMenuContext = () =>
  useNonNullableContext(MenuContext, "useMenuContext");

interface MenuProviderProps {
  children: ReactNode;
}

const MenuProvider = ({ children }: MenuProviderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const value: MenuContextProps = useMemo(
    () => ({
      menuIsOpen,
      setMenuIsOpen,
    }),
    [menuIsOpen],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
